import { useContext, useEffect, useMemo, useState } from "react";
import { store } from "./Store";

export const NetworkStatusDetector = ({ children }) => {
	const { dispatch } = useContext(store);
	const [online, setOnline] = useState(undefined);
	const memoOnline = useMemo(() => online, [online]);

	const detectNetworkDetection = async (abortController) => {
		try {
			const response = await fetch("https://ipv4.icanhazip.com", {
				signal: abortController.signal,
			});
			setOnline(response.ok);
		} catch (error) {
			if (error.name === "AbortError") {
				console.log("Petición cancelada");
			} else {
				console.log("Error al hacer fetch", error);
				setOnline(false);
			}
		}
	};

	useEffect(() => {
		dispatch({
			type: "CHANGE_VALUE",
			property: "isOnline",
			value: memoOnline,
		});
	}, [memoOnline]);

	useEffect(() => {
		let abortController = new AbortController();

		const checkConnection = () => {
			abortController.abort();
			abortController = new AbortController();
			detectNetworkDetection(abortController);
		};

		checkConnection();

		const intervalId = setInterval(checkConnection, 2000);

		return () => {
			clearInterval(intervalId);
			abortController.abort();
		};
	}, []);

	return children;
};
