import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { store } from "../components/Store";
import { READER_ENV } from "../config/constants";
import { ModalCompartir } from "../components/modals/modal-compartir";
import { useBookData } from "../fichas/useBookData";
import MicroModal from "micromodal";

export const Reader = () => {
	const globalState = useContext(store);
	const lang = globalState?.state?.idioma;
	const params = useParams();
	const bookId = params?.idLibro;
	const book = useBookData(bookId);

	const readerSrc = `/makemake-reader/index.html
	?bookId=${bookId}
	&lang=${lang}
	&platform=classics
	&env=${READER_ENV}
	&appToken=null
	&downloaded_in_app=false
	`;

	useEffect(() => {
		window.addEventListener("message", handleReaderEvents);
		return () => {
			window.removeEventListener("message", handleReaderEvents);
		};
	}, [bookId]);

	const handleReaderEvents = (event) => {
		handleActivityClickEvent(event);
		handleShareClickEvent(event);
	};

	const handleActivityClickEvent = (e) => {
		const data = e.data;
		const eventId = data?.eventId;
		if (eventId === "MAKEMAKE_READER_EVENT:ACTIVITY_CLICK") {
			const url = data?.payload?.activityUrl;
			window.open(url, "_blank");
		}
	};

	const handleShareClickEvent = (e) => {
		const data = e.data;
		const eventId = data?.eventId;
		if (eventId === "MAKEMAKE_READER_EVENT:SHARE_CLICK") {
			openShareModal();
		}
	};

	const openShareModal = () => {
		MicroModal.show("modal-compartir-visor", {
		  awaitCloseAnimation: true,
		});
	  };

	return (
		<>

			<div style={{ width: "100dvw", height: "100dvh", overflow: "hidden" }}>
				<iframe
					title="Reader"
					key={bookId}
					className="w-100 h-100"
					src={readerSrc}
					style={{ border: "none", outline: "none" }}
				></iframe>
			</div>
		     <ModalCompartir idModal={"modal-compartir-visor"} libro={book.metadata} />
		</>
	);
};
